import { gql } from '@apollo/client';
import { AddIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  HStack,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { isNil } from 'lodash';
import React from 'react';
import { FirmwareRowFragment } from './__generated__/FirmwareTable.graphql';

interface Props {
  platforms: FirmwareRowFragment[];
  onNewFirmware: (platform: FirmwareRowFragment) => void;
}

export default function FirmwareTable({ platforms, onNewFirmware }: Props) {
  return (
    <Table width="5xl" rounded="md" p="2" variant="simple">
      <TableHeader />
      <Tbody>
        {platforms.map((platform) => (
          <TableRow key={platform.id} platform={platform} onNewFirmware={onNewFirmware} />
        ))}
      </Tbody>
    </Table>
  );
}

function TableHeader() {
  return (
    <Thead pt="2" pb="4" w="full">
      <Tr>
        <Th>Hardware Platform</Th>
        <Th>Platform info</Th>
        <Th>Current Firmware</Th>
      </Tr>
    </Thead>
  );
}

function TableRow({
  platform,
  onNewFirmware,
}: {
  platform: FirmwareRowFragment;
  onNewFirmware: (platform: FirmwareRowFragment) => void;
}) {
  return (
    <Tr>
      <Td>
        {[platform.name, platform.version].filter(Boolean).join(' ')}
        <Badge variant="outline" ml="2">
          {platform.type}
        </Badge>
      </Td>
      <Td>{platform.description}</Td>
      <Td>
        <HStack spacing="2" justifyContent="space-between">
          {platform.firmwareReleases.android.length ? (
            <Box>
              {platform.firmwareReleases.android.map(({ version, url, scalarVersion }, index) => (
                <React.Fragment key={index}>
                  {url ? (
                    <Link
                      href={url}
                      isExternal
                      color="blue.500"
                      textDecoration="underline"
                      _hover={{ color: 'blue.700' }}
                    >
                      {version}
                      {!isNil(scalarVersion) && ` + ${scalarVersion}`}
                    </Link>
                  ) : (
                    <span>
                      {version}
                      {!isNil(scalarVersion) && ` + ${scalarVersion}`}
                    </span>
                  )}
                  {index < platform.firmwareReleases.android.length - 1 && ', '}
                </React.Fragment>
              ))}
            </Box>
          ) : (
            <Box>
              <Text>No firmware available</Text>
            </Box>
          )}
          <Button
            leftIcon={<AddIcon />}
            flexShrink={0}
            size="xs"
            variant="outline"
            onClick={() => onNewFirmware(platform)}
          >
            New Firmware
          </Button>
        </HStack>
      </Td>
    </Tr>
  );
}

FirmwareTable.graphql = {
  fragment: gql`
    fragment FirmwareRow on Platform {
      id
      name
      version
      description
      type
      firmwareReleases {
        android {
          version
          url
          scalarVersion
        }
      }
    }
  `,
};
