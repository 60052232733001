/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type FirmwareRowFragment = { __typename?: 'Platform', id: string, name: string, version?: string | null, description?: string | null, type: Types.PlatformType, firmwareReleases: { __typename?: 'FirmwareReleases', android: Array<{ __typename?: 'AndroidFirmware', version: any, url?: string | null, scalarVersion?: string | null }> } };

export const FirmwareRowFragmentDoc = gql`
    fragment FirmwareRow on Platform {
  id
  name
  version
  description
  type
  firmwareReleases {
    android {
      version
      url
      scalarVersion
    }
  }
}
    `;